.navbarClass {
  position: fixed;
  width: 100vw;
  color: var(--color-primary-dark);
  font-size: larger;
  background-color: var(--color-primary-white);
  box-shadow: -1px 0 10px -2px rgba(0, 0, 0, 0.3607843137);
  z-index: 9999;
  background-color: var(--color-primary-pink);
}
.navbarClass .cartHeaderText {
  font-size: 24px;
  font-weight: 600;
}
.navbarClass .cartHeaderText span {
  font-weight: 300;
  font-size: 22px;
  margin-left: 5px;
  color: var(--color-primary-gray);
}
.navbarClass .navbarContainer {
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
}

.navbarItem {
  cursor: pointer;
}

.cartIcon {
  margin-right: 5px;
  width: 30px;
  height: auto;
  margin-top: 5px;
  cursor: pointer;
}

.cartClose {
  cursor: pointer;
}

.navbarHeader {
  cursor: pointer;
  font-size: 2em;
  font-weight: 300;
  position: fixed;
  top: 33px;
  left: 50%; /* Position the text 50% from the left */
  transform: translate(-50%, -50%); /* Center the text both horizontally and vertically */
}

.cartWrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  box-sizing: border-box;
  height: 100dvh;
  width: 100vw;
  transition: transform 1s ease-in-out;
  background-color: var(--color-primary-white);
  color: var(--color-primary-dark);
  box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.3607843137);
}

.cartWrapperVisible {
  transform: translateX(-100vw);
}

.cartBottom {
  z-index: 999;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: var(--color-primary-pink);
  padding: 25px 0px;
}

.cartClose {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--color-primary-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.cartClose img {
  width: 17px;
}

.cartProduct {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.cartProduct img {
  height: 70px;
}
.cartProduct .actionButton {
  padding: 0;
  width: 30px;
  height: 30px;
  color: #222;
  background: #fff;
  border: 1px solid #efefef;
}
.cartProduct .actionButton:hover {
  background: #efefef;
}
.cartProduct .cartProductTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.cartProduct .cartProductTitle img {
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 50%;
  margin-bottom: 2px;
}
.cartProduct .cartProductButtons {
  display: flex;
}
.cartProduct .cartProductButtonsWrapper {
  display: flex;
  justify-content: space-between;
}
.cartProduct .cartProductQuantity {
  padding: 0;
  width: 50px;
  height: 30px;
  color: #222;
  background: #fff;
  border: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.cartProduct .cardProductRightWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.cartProduct .cartItemPrice {
  font-size: 16px;
}

.cartHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin-top: 10px;
  margin-bottom: 30px;
}

.cartProductLine {
  width: 100%;
  height: 1px;
  background-color: var(--color-primary-gray);
  margin-top: 20px;
  margin-bottom: 20px;
}

.cartLine {
  width: 100vw;
  height: 3px;
  background-color: var(--color-primary-gray);
  margin-bottom: 20px;
  margin-left: -10px;
}

.cartIconWrapper {
  display: flex;
  align-items: center; /* vertically center items */
  justify-content: center; /* horizontally center items */
  position: relative;
}

.cartSize {
  position: absolute;
  top: 45%;
  margin-right: 5px;
  font-size: 12px;
  color: black;
  border-radius: 50%;
}

.shopItemContainer {
  padding-bottom: 40px;
}

.cartBottomCheckout {
  background-color: transparent;
  padding: 10px 20px;
  text-align: center;
  color: var(--color-primary-dark);
  margin: 10px 0px;
  cursor: pointer;
  border: 1px solid var(--color-primary-dark);
}

.cartBottomCheckout:hover {
  background-color: var(--color-primary-dark);
  padding: 10px 20px;
  text-align: center;
  color: var(--color-primary-white);
  margin: 10px 0px;
  cursor: pointer;
  border: 1px solid var(--color-primary-dark);
}

.cartBottomContinueCheckout {
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.cartBottomPriceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cartBottomPriceRow .cartBottomShippingLeft {
  font-size: 16px;
}
.cartBottomPriceRow .cartBottomShippingRight {
  font-weight: 600;
  font-size: 12px;
  opacity: 50%;
}
.cartBottomPriceRow .cartBottomSubtotalLeft {
  font-size: 16px;
}
.cartBottomPriceLine {
  margin: 15px 0px;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary-dark);
  opacity: 10%;
}

.cartWrapperScroll {
  height: 100%;
  overflow-y: auto;
}

.navbarHeader {
  font-size: 32px;
}
.navbarHeader img {
  margin-right: 15px;
  width: 50px;
  margin-top: -10px;
}/*# sourceMappingURL=Navbar.css.map */