.shop {
  background-color: var(--color-primary-white);
  padding-top: 65px;
}

.shopHeader {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 1.6em;
  margin-top: 40px;
  margin-bottom: 40px;
}

.shopButtons {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--color-primary-gray);
  border-bottom: 2px solid var(--color-primary-gray);
  margin-bottom: 20px;
  color: #9b9797;
  font-size: 1em;
  letter-spacing: 2px;
}

.shopButtonsLine {
  width: 2px;
  height: auto;
  background-color: var(--color-primary-gray);
}

.shopButton {
  width: 300px;
  text-align: center;
  cursor: pointer;
}

.shopMenu {
  background: var(--color-primary-white);
  overflow: hidden;
  border-top: 3px solid var(--color-primary-dark);
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MenuWrapper {
  position: absolute;
  left: 0;
  clip: rect(auto, auto, 0, auto);
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  transition-property: clip;
  transition-duration: 1.5s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.MenuWrapper.show {
  clip: rect(auto, auto, 10rem, auto);
}

.shopItemImage {
  width: 100%;
  display: block;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  background-position: center;
}

.shopItemLaptop {
  flex-basis: 33% !important;
}

.shopItemPage .swiper-slide img {
  display: block;
  width: 100%;
  height: auto !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.shopItemPage .swiper-pagination {
  bottom: 20px !important;
}
.shopItemPage .swiper-pagination-bullet {
  background: var(--color-primary-dark) !important;
}

.shopItem {
  box-sizing: border-box;
  flex-basis: 50%;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 30px;
}

.shopItemsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.shopItemText {
  margin-top: 10px;
  line-height: 20px;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.shopItemText .shopItemTextPrice {
  font-size: 13px;
  opacity: 80%;
  letter-spacing: 1px;
}

.shopButtonText {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.shopButtonContainer {
  position: relative;
  z-index: 99;
}

.shopItemTitle {
  margin-top: 30px;
  display: flex;
  width: 100%;
  font-size: 28px;
  text-transform: uppercase;
  color: var(--color-primary-dark);
  font-weight: 400;
}

.shopItemPriceQuantity {
  display: flex;
  justify-content: space-between;
}

.shopItemPrice {
  display: flex;
  width: 100%;
  font-size: 20px;
  color: var(--color-primary-dark);
  opacity: 80%;
  margin-top: 10px;
}

.shopItemQuantity {
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}
.shopItemQuantity input {
  margin-top: 10px;
  width: 60px;
  height: 35px;
  text-align: center;
  color: var(--color-primary-dark);
  border: 1px solid rgb(240, 240, 240);
  opacity: 80%;
  outline: none;
}
.shopItemQuantity .shopItemQuantityButton {
  margin-top: 10px;
  width: 30px;
  height: 35px;
  color: var(--color-primary-dark);
  border: 1px solid rgb(240, 240, 240);
  opacity: 80%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.shopItemAddToCartButton {
  margin-top: 40px;
  width: 100%;
  padding: 12px;
  text-align: center;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  font-size: 13px;
  letter-spacing: 1.5px;
  margin-bottom: 40px;
  cursor: pointer;
}

.shopItemTabHeadersWrapper {
  display: flex;
}
.shopItemTabHeadersWrapper .shopItemTabHeader {
  cursor: pointer;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  opacity: 80%;
  padding: 5px 15px;
  font-size: 14px;
}
.shopItemTabHeadersWrapper .shopItemTabHeader.active {
  background-color: var(--color-primary-white);
  color: var(--color-primary-dark);
}

.shopItemTab {
  padding: 15px;
  margin-bottom: 60px;
}

.shopMenuItemSelected {
  color: var(--color-primary-dark);
}

.shopItemCarousel {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
}

.shopButtonsMobile {
  /* Style for each button (50% width) */
  /* Add styles for the left and right buttons */
  /* Style for the divider line */
}
.shopButtonsMobile .MenuWrapper {
  top: 45px;
}
.shopButtonsMobile .shopButtonContainer {
  display: flex;
  align-items: stretch;
  width: 100%;
}
.shopButtonsMobile .shopButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.shopButtonsMobile .shopButtonLeft {
  border-radius: 5px 0 0 5px; /* Add rounded corners to the left button */
}
.shopButtonsMobile .shopButtonRight {
  border-radius: 0 5px 5px 0; /* Add rounded corners to the right button */
}
.shopButtonsMobile .shopButtonsLine {
  width: 1px;
  background-color: #ccc;
}

.shopItemLaptop {
  padding: 30px;
}
.shopItemLaptop .shopItemTextTitle {
  font-size: 24px;
  margin-top: 15px;
}
.shopItemLaptop .shopItemTextPrice {
  margin-top: 10px;
  font-size: 20px;
}

.shopitemPageLaptop {
  padding-bottom: 40px;
}
.shopitemPageLaptop .swiper {
  margin-top: 40px;
}
.shopitemPageLaptop .shopItemTabHeadersWrapper {
  margin-top: 20px;
}
.shopitemPageLaptop .shopItemAddToCartButton {
  margin-bottom: 10px;
}
.shopitemPageLaptop .shopItemRow {
  flex-direction: column;
}

.shopItemColorOption label {
  margin-left: 10px;
}/*# sourceMappingURL=Shop.css.map */