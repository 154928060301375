.aboutUsImage {
  width: 100%;
}
.aboutUsCol {
  background-color: var(--color-primary-pink);
  text-align: center;
  color: var(--color-primary-dark);
  padding-top: 30px;
  padding-bottom: 35px;
  margin-top: -10px;
  h2 {
    font-weight: 300;
  }
}

.aboutUsImageContainer {
  position: relative;
  display: inline-block;
}

.aboutUsImage {
  display: block;
}

.aboutUsImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44);
  opacity: 0.2;
}

.aboutUsImageText {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-white);
  text-align: center;
  font-weight: 300;
}

.aboutUsPhilosophy {
  padding-bottom: 30px;
}

.aboutUsPhilosophyWrapper {
  padding-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 5px;
  color: var(--color-primary-dark);

  .aboutUsPhilosophyHeader {
    font-size: 1.6em;
    font-weight: 300;
  }
  .aboutUsPhilosophyText {
    font-style: italic;
  }
}

.philosophyItem {
  text-align: center;
  img {
    width: 40px;
  }
}

.philosophyItemLine {
  flex-grow: 1;
  height: 1px;
  background-color: var(--color-primary-dark);
  margin: 25px;
}

.philosophyItemTitle {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.philosophyDescription {
  margin-top: 10px;
  margin-bottom: 20px;
}

.contactPage {
  text-align: center;
  padding: 10px;
  color: var(--color-primary-dark);
}

.contactPageheader {
  margin-top: 20px;
}
.contactPageContainer {
  .checkoutRow {
    margin-top: 15px;
  }
}
.contactFormSubmit {
  margin-top: 20px;
  border: none;
  padding: 8px 25px;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
}
.faqQuestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  margin-top: 15px;
}
.faqAnswer {
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  padding: 10px 20px;
  border-top: 2px solid var(--color-primary-dark);
}

.shippingPageLine {
  width: 100%;
  height: 1px;
  background-color: var(--color-primary-gray);
  margin-top: 15px;
  margin-bottom: 15px;
}

.shippingPage {
  h3 {
    margin-bottom: 20px;
  }
  font-size: 0.8em;
}

@media screen and (min-width: 800px) {
  .aboutUsImageContainer2 {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 500px;
      margin-top: -10px;
      z-index: 999;
    }
    .aboutUsImageOverlay {
      width: 500px;
      left: auto;
      margin-top: -10px;
    }
  }
  .aboutUsImageContainer1 {
    width: 100vw;
    height: 400px;
    overflow: hidden;
    .aboutUsImage1 {
      object-fit: cover;
      margin-top: -10%;
    }
    .aboutUsImageText {
      font-size: 3rem;
    }
  }
  .aboutUsCol {
    font-size: 1.6rem;
    h2 {
      font-size: 2.6rem;
    }
  }
}
