.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100dvh;
  -o-object-fit: cover;
     object-fit: cover;
}

.swiper-pagination {
  bottom: 30px !important;
}

.swiper-pagination-bullet-active {
  background: var(--color-primary-dark) !important;
}

.swiper-pagination-bullet {
  background: var(--color-primary-white) !important;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.2); /* 10% opacity black */
  z-index: 9;
}

.homeHeroHeader {
  font-size: 1.4em;
  color: var(--color-primary-white);
  white-space: nowrap;
  font-weight: 500;
}

.homeHeroText {
  font-size: 1.1em;
  font-weight: 300;
  color: var(--color-primary-white);
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.homeShopButtonContainer {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  bottom: 70px;
  z-index: 99;
}

.homeShopButton {
  font-size: 1.1rem;
  margin-top: 7px;
  color: var(--color-primary-dark);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  background-color: var(--color-primary-white);
  border: 2px solid var(--color-primary-white);
}

.homeAbout {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-dark);
}

.homeAboutWrapper {
  background-color: var(--color-primary-white);
}

.homeAboutText {
  text-align: center;
}

.homeAboutText {
  font-style: italic;
}

.homeAboutButton {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid var(--color-primary-dark);
  width: -moz-fit-content;
  width: fit-content;
}

.homeAboutHeader {
  font-weight: 300;
}

.homeCategoriesItem {
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  background-position: center;
  display: flex;
  padding-left: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: flex-end;
}

.homeCategoriesItem1 {
  background-image: linear-gradient(0deg, rgba(39, 40, 40, 0.6), rgba(47, 48, 48, 0.6)), url("../../images/categoriesSkincare.jpg");
}

.homeCategoriesItem2 {
  background-image: linear-gradient(0deg, rgba(39, 40, 40, 0.6), rgba(47, 48, 48, 0.6)), url("../../images/categoriesHair.jpg");
}

.homeCategoriesItem3 {
  background-image: linear-gradient(0deg, rgba(39, 40, 40, 0.6), rgba(47, 48, 48, 0.6)), url("../../images/categoriesBody.jpg");
}

.homeCategoriesItemTitle {
  font-size: 1.4em;
  color: white;
}

.homeCategoriesItemButton {
  margin-top: 15px;
  background-color: white;
  padding: 10px 20px;
  border: 2px solid white;
  color: var(--color-primary-dark);
  width: -moz-fit-content;
  width: fit-content;
}

.homeBundles {
  background-image: linear-gradient(0deg, rgba(39, 40, 40, 0.6), rgba(47, 48, 48, 0.6)), url("../../images/bundle.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: -moz-fit-content;
  height: fit-content;
  background-position: center;
  color: var(--color-primary-white);
}
.homeBundles .container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBundles .container .row {
  height: 100%;
  width: 100%;
}
.homeBundles .container .row .col {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.homeBundlesContainer {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.homeBundlesHeader {
  text-transform: uppercase;
  font-size: 1.8em;
  font-weight: 300;
}

.homeBundlesBody {
  letter-spacing: 0.05em;
  font-size: 1.3em;
  font-weight: 400;
}

.homeBundlesButton {
  margin-top: 10px;
  border: 2px solid var(--color-primary-white);
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
}

.homeStones {
  background-color: var(--color-primary-pink);
}

.homeStonesWrapper {
  padding-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 5px;
  color: var(--color-primary-dark);
}
.homeStonesWrapper .homeStonesHeader {
  font-size: 1.6em;
  font-weight: 300;
}
.homeStonesWrapper .homeStonesText {
  font-style: italic;
}

.homeCarousel {
  cursor: grab;
  overflow: hidden;
}
.homeCarousel .item {
  min-width: 70vw;
}
.homeCarousel .homeInnerCarousel {
  display: flex;
  margin-left: 15vw;
}
.homeCarousel .homeSliderImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.homeCarousel .homeSliderImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(189, 189, 189, 0.5);
  opacity: 50%;
}

.homeFeaturedCollection {
  width: 100%;
  text-align: center;
  color: var(--color-primary-dark);
  margin-top: 40px;
  line-height: 30px;
  font-weight: 300;
}

.homeSliderDescription {
  text-align: center;
  line-height: 20px;
  font-weight: 300;
}

.homeFeaturedButton {
  padding: 10px 30px;
  border: 2px solid var(--color-primary-dark);
  background-color: var(--color-primary-dark);
  color: var(--color-primary-white);
}

.dividerLine {
  width: 100vw;
  height: 2px;
  background-color: var(--color-primary-dark);
  opacity: 10%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.homeCategoriesHeader {
  font-family: var(--font-header);
  color: var(--color-primary-dark);
  font-weight: 300;
  font-size: 1.5em;
  font-style: italic;
  text-align: center;
}

.homeInsta {
  padding-bottom: 60px;
}
.homeInsta .homeInnerCarousel {
  margin-left: 0px;
}
.homeInsta .homeSliderImage {
  border: 1px solid var(--color-primary-dark);
  border-right: 0px;
}

.homeCarouselInsta {
  cursor: grab;
  overflow: hidden;
}
.homeCarouselInsta .item {
  min-width: 70vw;
}
@media screen and (min-width: 800px) {
  .homeCarouselInsta .item {
    min-width: 30vw !important;
  }
}
.homeCarouselInsta .homeInnerCarousel {
  display: flex;
}
.homeCarouselInsta .homeSliderImage {
  margin-left: 1px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.homeCarouselInsta .homeSliderImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(189, 189, 189, 0.5);
  opacity: 50%;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
}

.reveal.active {
  transform: translateY(0px);
  opacity: 1;
}

@media screen and (min-width: 851px) {
  .homeHeroText {
    font-size: 32px;
    line-height: 35px;
  }
  .homeShopButton {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
  }
  .menuCircle {
    margin-left: -20px;
  }
}
.homeDesktopFeaturedImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.homeAboutDesktop {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 0px;
  font-size: 18px;
}
.homeAboutDesktop .homeAboutHeader {
  font-size: 34px;
}
.homeAboutDesktop .homeAboutText {
  margin-top: 10px;
  margin-bottom: 10px;
}

.shopAllButton {
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  border: 1px solid var(--color-primary-pink);
}

.shopAllButton:hover {
  background-color: var(--color-primary-dark);
  color: var(--color-primary-white);
  border: 1px solid var(--color-primary-dark);
  cursor: pointer;
}/*# sourceMappingURL=HomeBody.css.map */