body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

:root {
  /* --color-primary-dark: #433a43; */
  --color-primary-gray: rgb(234, 228, 217);
  --color-primary-dark: #2f2a2f;
  --color-primary-white: #fffdff;
  --color-primary-pink: #ffe0e7;
  --font-header: "Playfair Display", serif;
}

.cursorPointer {
  cursor: pointer;
}

body {
  overflow-x: hidden;
}
.blurHashImage {
  aspect-ratio: 1/1;
  height: auto !important;
}
