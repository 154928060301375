.footer {
  background-color: var(--color-primary-pink);
  padding-top: 40px;
  padding-bottom: 40px;
  color: var(--color-primary-dark);
}

.footerHeader {
  font-size: 2em;
  font-weight: 300;
}

.footerSubscribeText {
  margin-top: 15px;
  font-size: 1.1em;
}
.footerSubscribeEmail {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
}
.footerSubscribeEmail::placeholder {
  text-align: center;
}
.footerSubscribeButton {
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  background-color: var(--color-primary-pink);
  margin-bottom: 20px;
  color: var(--color-primary-dark);
  border: 1px solid var(--color-primary-dark);
}
.footerLinks {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  gap: 5px;

  cursor: pointer;
}

.footerContactLinks {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  gap: 5px;
  text-align: right;
  img {
    margin-left: 15px;
    width: 25px;
  }
}
.footerLink {
  img {
    cursor: pointer;
  }
}
.footerRef {
  margin-top: 15px;
  font-size: 0.9em;
  opacity: 65%;
}

.laptopFooter {
  .footerSubscribeText {
    margin: 0;
  }
  font-size: 20px;
  .footerHeader {
    margin-left: 10px;
    font-size: 62px;
  }
  .footerContactLinks {
    text-align: left;
    margin-left: -15px;
    margin-top: 5px;
  }
  .footerRowLaptop {
    margin-left: -30px;
  }
  .footerRef {
    margin-left: 15px;
  }
}

.footerSubscribeLaptop {
  display: flex;
  align-items: stretch;
  margin-top: 5px;
  .footerSubscribeEmail {
    border: 1px solid var(--color-primary-dark);
    height: 60px;
    flex: 1; /* Allow the input to grow and take available space */
  }
  .footerSubscribeButton {
    border: 1px solid var(--color-primary-dark);
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 20px;
  }
}
