#menuLottie {
  width: 50px;
  height: auto;
  margin-top: 5px;
}

.menuCircle {
  cursor: pointer;
  border-radius: 50%;
  z-index: 999;
}

.navbarMenu {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  background-color: var(--color-primary-white);
  z-index: 98;
}

.hidden {
  display: none;
}

.hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}

.hamburger-menu .menu-secondary-background-color {
  background: var(--color-primary-dark);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin-top: 65px;
}

.hamburger-menu .menu-layer {
  background: var(--color-primary-white);
  height: 100%;
  overflow: hidden;
  margin-top: 65px;
  border-top: 3px solid var(--color-primary-dark);
}

.hamburger-menu .menu-layer .wrapper {
  position: relative;
}

.hamburger-menu .menu-layer .wrapper .menu-links {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  top: 20px;
}

.navbarLine {
  width: 100%;
  height: 2px;
  background-color: var(--color-primary-dark);
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 20%;
}

.navbarItem {
  white-space: nowrap;
  font-size: 1.2em;
  font-weight: 300;
}

.navbarShopIcon {
  margin-right: 30px;
}

.hiddenShop {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.navbarShop {
  max-height: 1000px;
  display: block;
  overflow: visible;
}

.navbarShopItem {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.7em;
  margin-left: 5px;
  cursor: pointer;
}

.shopLine {
  margin-top: 0;
  width: 200vw;
}

.socialLinks {
  white-space: nowrap;
}
.socialLinks img {
  margin-top: 10px;
  margin-right: 15px;
  width: 25px;
}/*# sourceMappingURL=NavbarAnimation.css.map */