.checkoutBody {
  padding-bottom: 40px;
}

.checkoutRow {
  font-weight: 300;
}
.checkoutRow .checkoutInput {
  position: relative;
  margin-bottom: 15px;
}
.checkoutRow .selectedInput label {
  top: 0;
  margin-top: 3px;
  font-size: 0.75em;
  opacity: 70%;
}
.checkoutRow .selectedInput input,
.checkoutRow .selectedInput textarea {
  padding-top: 1.3em;
  padding-bottom: 0.3em;
}
.checkoutRow input,
.checkoutRow textarea {
  border: 1px var(--color-primary-gray) solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8em 0.9em;
  word-break: normal;
  line-height: inherit;
  font-size: 0.85em;
  outline: none;
}
.checkoutRow label {
  position: absolute;
  top: 12px;
  margin-left: 10px;
  font-size: 0.85em;
  font-weight: 500;
  transition: 0.5s;
  pointer-events: none;
  color: var(--color-primary-dark);
  opacity: 80%;
}

.checkoutHeader {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.1em;
}

.checkoutContinueButton {
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  cursor: pointer;
}
.checkoutContinueButton button {
  background-color: transparent;
  border: none;
}

.checkoutBackButton {
  color: var(--color-primary-dark);
  text-align: center;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}

.checkoutShippingRowRight {
  cursor: pointer;
}

.checkoutBreadcrumbs {
  font-size: 0.8em;
}
.checkoutBreadcrumbs svg {
  margin: 0px 5px;
  margin-bottom: 3px;
}

.checkoutSummary {
  background: var(--color-primary-white);
  border-bottom: 1px solid var(--color-primary-gray);
  border-top: 1px solid var(--color-primary-gray);
  width: 100vw;
  margin-bottom: 20px;
  padding: 20px 0px;
}

.checkoutSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkoutSummaryLeft {
  display: flex;
  align-items: center;
}

.checkoutSummaryCart {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.checkoutSummaryDown {
  font-size: 1.2em;
  margin-left: 10px;
}

.checkoutOrderSummary {
  width: 100vw;
  height: 0;
  overflow: hidden;
  transition: height 1.5s;
}

.checkoutOrderSummary.show {
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--color-primary-white);
  margin-top: -15px;
  margin-bottom: 20px;
  padding-top: 15px;
  border-bottom: 1px solid var(--color-primary-gray);
}

.checkoutCartProduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.checkoutCartItemLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
}

.checkoutCartItemRight {
  font-size: 0.8em;
}

.checkoutCartItemImage {
  position: relative;
  display: inline-block;
}

.checkoutCartItembadge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--color-primary-dark);
  color: var(--color-primary-gray);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.checkoutCartItemBadgeText {
  display: block;
  text-align: center;
  line-height: 1;
}

.checkoutCartPrices {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.checkoutCartPrice,
.checkoutCartTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkoutCartPrice .checkoutCartPriceRight1,
.checkoutCartTotal .checkoutCartPriceRight1 {
  font-size: 0.9em;
}
.checkoutCartPrice .checkoutCartPriceRight2,
.checkoutCartTotal .checkoutCartPriceRight2 {
  opacity: 50%;
  font-size: 0.7em;
}
.checkoutCartPrice .checkoutCartPriceLeft,
.checkoutCartPrice .checkoutCartTotalLeft,
.checkoutCartTotal .checkoutCartPriceLeft,
.checkoutCartTotal .checkoutCartTotalLeft {
  font-weight: 300;
}

.checkoutCartTotal {
  padding-bottom: 25px;
}

.checkoutShippingContainter {
  padding-top: 15px;
}

.checkoutShipping {
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid var(--color-primary-gray);
  border-radius: 5px;
  font-size: 0.8em;
}
.checkoutShipping .cartProductLine {
  margin: 10px 0px;
}
.checkoutShipping .checkoutShippingSelectedCircle {
  width: 20px;
  height: 20px;
  background-color: var(--color-primary-dark);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkoutShipping .checkoutShippingSelectedCircleInside {
  width: 6px;
  height: 6px;
  background-color: var(--color-primary-white);
  border-radius: 50%;
  margin-top: 6px;
}
.checkoutShipping .checkoutShippingRowLeftShipping {
  display: flex;
  gap: 10px;
  align-items: center;
}
.checkoutShipping .checkoutShippingRowLeftShipping div {
  margin-top: 5px;
}

.checkoutShippingShip {
  padding-top: 10px;
  padding-bottom: 10px;
}
.checkoutShippingShip .checkoutShippingRow {
  margin-top: 5px;
  align-items: center;
}

.checkoutShippingRow {
  display: flex;
  justify-content: space-between;
}
.checkoutShippingRow span {
  margin-left: 3px;
  margin-right: 3px;
}
.checkoutShippingRow div {
  margin-bottom: 5px;
  opacity: 80%;
}

.checkoutShippingRowLeft {
  width: 50%;
  white-space: nowrap;
}

.discountCodeRight {
  border-radius: 5px;
  background-color: var(--color-primary-pink);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  height: 43px;
}

.discoutCodeRow {
  margin-bottom: 30px;
}

.discountCodeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}
.discountCodeContainer .checkoutInput {
  flex: 8;
}

.confirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationModal-content {
  background-color: #fff;
  padding: 20px;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Optionally, you can style the Close button */
.confirmationModal-content button {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: var(--color-primary-pink);
  color: var(--color-primary-dark);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.finaliseButton {
  padding: 5px 10px;
  background-color: var(--color-primary-dark);
  width: 100%;
  height: 100%;
}
.finaliseButton button {
  color: var(--color-primary-white);
}

.continueToPaymentButton {
  padding: 5px 10px;
}

.discountSpans {
  margin-top: -25px;
  margin-bottom: 25px;
}/*# sourceMappingURL=Checkout.css.map */